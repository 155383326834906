<template>
  <main>
    <div class="holder">
      <back-button />

      <section class="form full">
        <h1 v-if="$route.name === 'addPartner'">
          {{ $t("add-partner.become-partner") }}
        </h1>
        <h1 v-else-if="$route.name === 'editPartner'">
          {{ $t("add-partner.edit-partner") }}
        </h1>

        <form action="#" method="post" onsubmit="return false;">
          <div class="section" id="upload-logo">
            <h2>{{ $t("add-partner.upload-logo") }}</h2>

            <div class="row">
              <form ref="fileInput" enctype="multipart/form-data">
                <input
                  type="file"
                  name="photo"
                  id="upload-file-input"
                  accept="image/*"
                  @change="uploadLogo"
                  :disabled="newLogo === 'load'"
                />
              </form>
              <div
                v-if="newLogo == 'load' || newLogo == null"
                class="field"
                id="upload-file-field"
              >
                <span v-if="newLogo === null" class="js-upload-file">{{
                  $t("add-partner.add-logo")
                }}</span>
                <div v-else style="justify-self: center;align-self: center;">
                  <loading-progress
                    :progress="0"
                    :indeterminate="true"
                    :counter-clockwise="false"
                    :hide-background="false"
                    shape="circle"
                    :size="32"
                  />
                </div>
              </div>
              <div
                v-else
                class="field uploaded"
                id="reupload-file-field"
                :style="logoStyle"
              >
                <img :src="createResourceUrl(newLogo)" alt="" />
                <span class="edit js-upload-file">{{
                  $t("add-partner.change")
                }}</span>
              </div>
              <div class="bg-color">
                <div class="title">{{ $t("add-partner.color") }}</div>
                <input
                  type="text"
                  name="color"
                  placeholder="#000000"
                  maxlength="7"
                  id="upload-logo-color"
                  v-model="logoStyle['background-color']"
                  @input="formatColor($event)"
                  @keypress="isLetterOrNumber($event)"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="sale" :style="logoStyle">
              <span class="logo-holder">
                <img :src="createPreviewResourceUrl(newLogo)" />
              </span>
              <span class="title"
                ><strong>{{
                  partnerData.title
                    ? partnerData.title
                    : $t("add-partner.title")
                }}</strong></span
              >
              <span class="actions">{{ $t("add-partner.no-specials") }}</span>
            </div>
          </div>

          <div class="section" id="upload-files">
            <h2>{{ $t("add-item.choose-photo") }}</h2>

            <div class="field" id="upload-files-field">
              <p>
                {{ $t("add-item.drag-here") + " " + $t("add-item.or") + " "
                }}<a href="#" class="js-upload-file">{{
                  $t("add-item.choose-on-pc")
                }}</a>
              </p>
              <form ref="fileInput" enctype="multipart/form-data">
                <input
                  type="file"
                  name="photo"
                  id="upload-files-input"
                  multiple
                  accept="image/*"
                  @change="uploadPhotoViaExplorer"
                />
              </form>
              <ul>
                <li
                  v-for="(item, index) in files"
                  :key="index"
                  v-if="item.id !== 'del'"
                  style="display: grid;"
                >
                  <div
                    v-if="item.id === 'load'"
                    style="justify-self: center;align-self: center;"
                  >
                    <loading-progress
                      :progress="0"
                      :indeterminate="true"
                      :counter-clockwise="false"
                      :hide-background="false"
                      shape="circle"
                      :size="loadingIconSize"
                    />
                  </div>
                  <div v-else>
                    <img :src="createResourceUrl(item.id)" alt="" />
                    <span class="close" @click="removePhoto(index)"></span>
                  </div>
                </li>
                <li>
                  <a ref="addImg" class="js-upload-file">
                    <span>{{ $t("add-item.add-photo") }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <h2>{{ $t("add-partner.title") }}</h2>
            <input
              type="text"
              name="title"
              :placeholder="$t('add-partner.enter-title')"
              maxlength="255"
              v-model="partnerData.title"
            />
          </div>

          <div class="section">
            <h2>{{ $t("add-partner.description") }}</h2>
            <textarea
              name="description"
              :placeholder="$t('add-partner.enter-description')"
              class="js-autoheight"
              v-model="partnerData.description"
            ></textarea>
          </div>

          <div class="section" id="choose-goods-category">
            <h2>{{ $t("add-partner.goods-category") }}</h2>
            <div class="select" id="category-goods-select">
              <div class="input-holder">
                <input
                        type="text"
                        name="category_text"
                        :placeholder="$t('add-partner.choose-category')"
                        readonly
                        :value="partnerData.goodsCategoryName"
                />
              </div>
              <ul class="dropdown">
                <li
                        v-for="item in goodsCategories"
                        @click="chooseGoodsCategory(item)"
                >
                  <div class="ico-holder food">
                    <img
                            :src="createResourceUrl(item.resourceId)"
                            :alt="item.name"
                            :title="item.name"
                    />
                  </div>

                  <div class="title">{{ item.name }}</div>
                  <p>{{ item.description }}</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="section" id="choose-category">
            <h2>{{ $t("add-partner.category") }}</h2>
            <div class="select" id="category-select">
              <div class="input-holder">
                <input
                  type="text"
                  name="category_text"
                  :placeholder="$t('add-partner.choose-category')"
                  readonly
                  :value="partnerData.categoryName"
                />
              </div>
              <ul class="dropdown">
                <li
                  v-for="item in partnerCategories"
                  @click="choosePartnerCategory(item)"
                >
                  <div class="ico-holder food">
                    <img
                      :src="createResourceUrl(item.resourceId)"
                      :alt="item.name"
                      :title="item.name"
                    />
                  </div>

                  <div class="title">{{ item.name }}</div>
                  <p>{{ item.description }}</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="section" id="contacts">
            <h2>{{ $t("add-partner.contacts") }}</h2>
            <input
              type="text"
              name="vk"
              placeholder="https://vk.com/..."
              v-model="contacts.vk"
            />
            <input
              type="text"
              name="telegram"
              placeholder="https://t.me/..."
              v-model="contacts.telegram"
            />
            <input
              type="text"
              name="phone"
              placeholder="+7..."
              v-model="contacts.phone"
            />
            <input
              type="text"
              name="email"
              placeholder="Email"
              v-model="contacts.email"
            />
            <input
              type="text"
              name="website"
              placeholder="https://"
              v-model="contacts.web"
            />
          </div>

          <div class="section" id="location">
            <h2>
              {{ $t("add-partner.dots")
              }}<span v-if="partnerData.geos.length > 0"
                >: {{ partnerData.geos.length }}</span
              >
            </h2>
            <div id="map" class="field" v-if="partnerData.geos.length">
              <GmapMap
                :center="{
                  lat: partnerData.geos[0].latitude,
                  lng: partnerData.geos[0].longitude
                }"
                :zoom="15"
                map-type-id="terrain"
                style="width: 100%; height: 100%"
                ref="mapRef"
                :options="{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false
                }"
                @click="onMapClicked"
              >
                <GmapMarker
                  v-if="!!google"
                  v-for="item in partnerData.geos"
                  :position="{ lat: item.latitude, lng: item.longitude }"
                  :draggable="false"
                  :icon="{
                    strokeColor: '#FFFFFF',
                    strokeOpacity: 1,
                    strokeWeight: 3,
                    fillColor: '#00D9B2',
                    fillOpacity: 1,
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 15,
                    anchor: new google.maps.Point(0, 0)
                  }"
                >
                </GmapMarker>
              </GmapMap>
            </div>
            <div
              class="field js-modal-link"
              data-target="#modal-choose-location"
              v-else
              @click="onMapClicked"
            >
              <span>{{ $t("add-partner.add-dots") }}</span>
            </div>
          </div>

          <button
            name="submit_btn"
            class="btn"
            v-if="$route.name === 'addPartner'"
            @click="onSavePartnerClicked"
          >
            {{ $t("add-partner.submit") }}
          </button>
          <button
            name="submit_btn"
            class="btn"
            v-else-if="$route.name === 'editPartner'"
            @click="onSavePartnerClicked"
          >
            {{ $t("add-partner.save") }}
          </button>
        </form>
      </section>
    </div>
  </main>
</template>

<script>
import uploadFiles from "../assets/js/init/uploadFiles";
import categorySelect from "../assets/js/init/categorySelect";
import { mapActions, mapGetters } from "vuex";
import BackButton from "../components/layouts/BackButton";
import { gmapApi } from "gmap-vue";
import { eventBus } from "../main";

export default {
  name: "AddPartner",
  components: { BackButton },
  title: "Add Partner",
  data() {
    return {
      partnerData: {
        title: null,
        description: null,
        geos: [],
        category: null,
        categoryName: null,
        goodsCategory: null,
        goodsCategoryName: null,
        logoResourceId: null,
        color: "#FFFFFF",
        resourceIds: [],
        contacts: []
      },
      contacts: {
        vk: null,
        telegram: null,
        email: null,
        phone: null,
        web: null
      },
      newLogo: null,
      files: [],
      loadingIconSize: null,
      logoStyle: {
        "background-color": "#FFFFFF"
      },
      previewLogo: require("../../public/images/icons/logo_gscale.svg")
    };
  },

  computed: {
    ...mapGetters(["partnerCategories"]),
    ...mapGetters(['goodsCategories']),
    google: gmapApi
  },
  // нельзя просто взять и перейти на страницу редактирования напрямую
  beforeRouteEnter(to, from, next) {
    if (to.params.id && !to.params.partner) {
      next({
        name: "partner",
        params: { id: to.params.id },
        replace: true
      });
    } else next();
  },

  created() {
    if (this.$route.params.partner) {
      let data = this.$route.params.partner;
      this.partnerData.logoResourceId = data.logoResourceId;
      this.newLogo = data.logoResourceId;
      this.partnerData.color = data.color;
      this.logoStyle["background-color"] = data.color;
      this.partnerData.title = data.title;
      this.partnerData.description = data.description;
      this.partnerData.category = data.categoryId;
      this.partnerData.categoryName = data.categoryName;
      this.partnerData.goodsCategory = data.goodsCategoryId;
      this.partnerData.goodsCategoryName = data.goodsCategoryName;
      if (data.geos) {
        this.partnerData.geos.push(...data.geos);
      }
      data.resourceIds.forEach(item => {
        this.files.push({ id: item });
      });
      for (const c of data.contacts) {
        this.contacts[c.type] = c.value;
      }
    }
    eventBus.$on("applyMarker", markers => {
      /*if(this.partnerData.geos.length > 0) {
          for (let i = this.partnerData.geos.length - 1; i >= 0; i--) {
            let founded = false;
            for (let k = 0; k < markers.length; k++) {
              if (this.partnerData.geos[i].latitude === markers[k].geo.latitude && this.partnerData.geos[i].longitude === markers[k].geo.longitude) {
                founded = true;
                markers.splice(k, 1);
                break;
              }
            }
            if (!founded) {
              this.partnerData.geos.splice(i, 1);
            }
          }
        }*/
      this.partnerData.geos.splice(0, this.partnerData.geos.length);
      markers.forEach(item => {
        this.partnerData.geos.push(item.geo);
      });
    });

    this.loadPartnerCategories();
    this.loadGoodsCategories();
  },

  mounted() {
    this.loaderIconInit();
    uploadFiles.init(this.uploadPhotoViaDrop);
    categorySelect.init();
    $("#upload-file-field .js-upload-file").click(function(e) {
      e.preventDefault();
      $("#upload-file-input").click();
    });
    $("#reupload-file-field .js-upload-file").click(function(e) {
      e.preventDefault();
      $("#upload-file-input").click();
    });
  },

  methods: {
    ...mapActions([
      "uploadFile",
      "savePartner",
      "editPartner",
      "loadPartnerCategories",
      "loadGoodsCategories",
    ]),
    loaderIconInit() {
      let that = this;
      this.loadingIconSize = this.$refs.addImg.clientHeight / 2;
      window.addEventListener("resize", function() {
        that.loadingIconSize = that.$refs.addImg.clientHeight / 2;
      });
    },
    isLetterOrNumber(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[A-Fa-f0-9]+$/.test(char)) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    formatColor(event) {
      let string = event.target.value;
      if (string.length > 0) {
        if (string.length === 1 && string[0] === "#") {
          this.logoStyle["background-color"] = "";
        } else if (string[0] === "#") {
          this.logoStyle["background-color"] = string.toUpperCase();
        } else {
          this.logoStyle["background-color"] = "#" + string.toUpperCase();
        }
      }
    },
    choosePartnerCategory(item) {
      this.partnerData.category = item.id;
      this.partnerData.categoryName = item.name;
      $("#category-select").removeClass("opened");
    },
      chooseGoodsCategory(item) {
          this.partnerData.goodsCategory = item.id;
          this.partnerData.goodsCategoryName = item.name;
          $("#category-goods-select").removeClass("opened");
      },
    uploadPhotoViaExplorer(event) {
      let files = [];
      files.push(...event.target.files);
      this.uploadPhoto(files);
    },
    uploadPhotoViaDrop(files) {
      this.uploadPhoto(files);
    },
    uploadPhoto(files) {
      this.$refs.fileInput.reset();
      for (let i = 0; i < files.length; i++) {
        let newFile = {
          id: "load"
        };
        this.files.push(newFile);
        let formData = new FormData();
        formData.append("file", files[i]);
        this.uploadFile(formData)
          .then(response => {
            newFile.id = response.data.id;
          })
          .catch(() => {
            newFile.id = "del";
            this.findAndDeleteBadPhoto();
            this.showToast(this.$t("toast.one-image-error"), "error");
          });
      }
    },

    uploadLogo(event) {
      let file = event.target.files[0];
      this.$refs.fileInput.reset();
      if (!file) {
        return;
      }

      this.newLogo = "load";
      let formData = new FormData();
      formData.append("file", file);
      this.uploadFile(formData)
        .then(response => {
          this.newLogo = response.data.id;
        })
        .catch(error => {
          this.newLogo = null;
          this.showToast(this.$t("toast.image-error"), "error");
        });
    },
    removePhoto(index) {
      this.files.splice(index, 1);
    },
    findAndDeleteBadPhoto() {
      for (let i = this.files.length - 1; i >= 0; i--) {
        if (this.files[i].id === "del") {
          this.files.splice(i, 1);
        }
      }
    },
    onSavePartnerClicked() {
      if (this.logoStyle["background-color"].length === 7) {
        this.partnerData.color = this.logoStyle["background-color"];
      } else {
        this.partnerData.color = null;
      }
      if (this.newLogo === "load") {
        return;
      } else {
        this.partnerData.logoResourceId = this.newLogo;
      }
      let newContacts = [];
      this.findAndDeleteBadPhoto();
      if (this.files.length > 0) {
        this.partnerData.favoriteResourceId = this.files[0].id;
        for (let i = 0; i < this.files.length; i++) {
          this.partnerData.resourceIds.push(this.files[i].id);
        }
      }

      if (this.contacts.vk)
        newContacts.push({ type: "vk", value: this.contacts.vk });
      if (this.contacts.telegram)
        newContacts.push({ type: "telegram", value: this.contacts.telegram });
      if (this.contacts.web)
        newContacts.push({ type: "web", value: this.contacts.web });
      if (this.contacts.phone)
        newContacts.push({ type: "phone", value: this.contacts.phone });
      if (this.contacts.email)
        newContacts.push({ type: "email", value: this.contacts.email });

      this.partnerData.contacts = newContacts;

      if (this.$route.name === "addPartner") {
        let that = this;
        this.savePartner(this.partnerData)
          .then(result => {
            this.showToast(this.$t("toast.partner-saved"));
            this.$router.replace({
              name: "partner",
              params: { id: result.data.id }
            });
          })
          .catch(error => this.showToast(error.response.data.message, "error"));
      } else if (this.$route.name === "editPartner") {
        this.partnerData.id = this.$route.params.id;
        this.editPartner(this.partnerData)
          .then(result => {
            this.showToast(this.$t("toast.partner-saved"));
            this.$router.replace({
              name: "partner",
              params: { id: result.data.id }
            });
          })
          .catch(error => this.showToast(error.response.data.message, "error"));
      }
    },
    createResourceUrl(id) {
      if (id) {
        return appSettings.baseUrl + "api/client/resource/" + id;
      } else {
        return null;
      }
    },
    createPreviewResourceUrl(id) {
      if (id) {
        return appSettings.baseUrl + "api/client/resource/" + id;
      } else {
        return this.previewLogo;
      }
    },
    onMapClicked() {
      eventBus.$emit("onChooseLocations", this.partnerData.geos);
    }
  }
};
</script>

<style scoped></style>
