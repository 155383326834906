export default {
  init(uploadMethod) {
    if ($('#upload-files').length) {
      $('#upload-files-field').on('dragenter dragover dragleave', function(e) {
        e.preventDefault();
        e.stopPropagation();
      });

      $('#upload-files-field').on('dragenter dragover', function() {
        $(this).addClass('dragover');
      });

      $('#upload-files-field').on('dragleave drop', function() {
        $(this).removeClass('dragover');
      });

      $('#upload-files-field').get(0).addEventListener('drop', function(e) {
        e.preventDefault();
        e.stopPropagation();

        e = e || window.event;
        let files = e.dataTransfer.files;
        uploadMethod(files);
        //handleUploadedFiles(files);
      });

      $('#upload-files-input').on('change', function() {
        //handleUploadedFiles(this.files);
      });

      $('#upload-files .js-upload-file').click(function(e) {
        e.preventDefault();

        $('#upload-files-input').click();
      });
    }
  }
}
