export default {
  init() {
    let that = this;
    $('.select').each(function(e, select) {
      $(select).find('.input-holder').click(function(e) {
        let $select = $(this).closest('.select');
        if ($select.hasClass('opened')) that.selectClose($select);
        else that.selectOpen($select);
      });
    });
    $('.select .dropdown li').click(function(e) {
      e.preventDefault();
      e.stopPropagation();
    });
  },

  selectOpen(select) {
    $(select).addClass('opened');
  },
  selectClose(select) {
    $(select).removeClass('opened');
  }
}
